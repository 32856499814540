import { Grid } from "@mui/material";
import Track from "components/Track";
import { SpotifyTrack } from "utils/constants";

interface Props {
  tracks: SpotifyTrack[];
  canRemoveTrack: boolean;
  addTrack?: (track: SpotifyTrack) => void;
  removeTrack?: (track: SpotifyTrack) => void;
}

const TrackList = ({
  tracks,
  canRemoveTrack,
  addTrack,
  removeTrack,
}: Props) => {
  return (
    <Grid container spacing={2}>
      {tracks.map((track: SpotifyTrack) => {
        return (
          <Track
            key={track.id}
            track={track}
            addTrack={addTrack}
            removeTrack={removeTrack}
            canRemoveTrack={canRemoveTrack}
          />
        );
      })}
    </Grid>
  );
};

export default TrackList;
