// Brand colors
export const mainWhite = "#FFFFFF";
export const mainGreen = "#1db954";
export const mainGreenMiddle = "#1ed760";
export const mainGreenMax = "#00ff00";
export const mainCircularProgressColor = mainWhite;

// Text
export const mainSecondaryText = "#808080";
export const albumInfoText = "#CCCCCC";

// UI Components
export const hoveredButtonTextColor = "#333333";
