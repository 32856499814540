import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import {
  hoveredButtonTextColor,
  mainCircularProgressColor,
  mainGreen,
  mainGreenMax,
  mainWhite,
} from "utils/colors";
import { SpotifyTrack } from "utils/constants";

const StyledSearchButton = styled(Button)({
  borderRadius: "0.5rem",
  backgroundColor: mainGreen,
  textAlign: "center",
  transition: "background-color 0.25s, box-shadow 0.25s",
  boxShadow: `0 0 0 1px ${mainWhite}, 0 0 0 0px ${mainGreenMax}`,
  color: mainWhite,
  fontWeight: 500,

  "&:hover": {
    backgroundColor: mainGreen,
    boxShadow: `0 0 0 3px ${mainWhite}, 0 0 8px 3px ${mainGreen}`,
  },

  "&:hover span": {
    color: "transparent",
    WebkitTextStroke: `0.5px ${hoveredButtonTextColor}`,
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: mainGreen,
    },
    "&:hover fieldset": {
      borderColor: mainGreen,
      border: `2px solid ${mainGreen}`,
    },
    "&.Mui-focused fieldset": {
      borderColor: mainGreen,
    },
  },
  "& .MuiInputLabel-root": {
    color: mainGreen,
  },
  "& input": {
    color: mainGreen,
  },
  width: "300px",
});

const StyledLoadingSpinner = styled(CircularProgress)({
  color: mainCircularProgressColor,
});

interface Props {
  isLoading: boolean;
  term: string;
  setTerm: React.Dispatch<React.SetStateAction<string>>;
  setSearchedTerm: React.Dispatch<React.SetStateAction<string>>;
  setSearchResults: React.Dispatch<React.SetStateAction<SpotifyTrack[]>>;
}

const SearchBar = ({ isLoading, term, setTerm, setSearchedTerm }: Props) => {
  const [showUpdatedTextFieldLabel, setShowUpdatedTextFieldLabel] =
    useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSearchedTerm(term);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        padding={1}
        my={2}
        borderRadius="0.5rem"
      >
        <Grid item>
          <StyledTextField
            autoFocus
            label={
              term
                ? "Enter A Song, Album, or Artist"
                : showUpdatedTextFieldLabel
                ? "Find a Track"
                : "Enter A Song, Album, or Artist"
            }
            variant="outlined"
            placeholder="Enter A Song, Album, or Artist"
            value={term}
            onChange={(event) => {
              setTerm(event?.target.value);
            }}
            onFocus={() => setShowUpdatedTextFieldLabel(true)}
            onBlur={() => setShowUpdatedTextFieldLabel(false)}
            InputLabelProps={{
              style: {
                color: mainGreen,
              },
            }}
          />
        </Grid>

        <Grid item>
          <StyledSearchButton
            type="submit"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <StyledLoadingSpinner size={12} />
                </Grid>
              </Grid>
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                  {"SEARCH".split("").map((letter, index) => (
                    <span style={{ transitionDelay: `${index * 0.03}s` }}>
                      {letter}
                    </span>
                  ))}
                </Grid>
              </Grid>
            )}
          </StyledSearchButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchBar;
