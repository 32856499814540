import { Button, Stack, TextField, Typography, styled } from "@mui/material";
import TrackList from "components/TrackList";
import React, { useCallback } from "react";
import {
  mainGreen,
  mainGreenMax,
  mainGreenMiddle,
  mainSecondaryText,
} from "utils/colors";
import { SpotifyTrack } from "utils/constants";

const StyledStack = styled(Stack)({
  minHeight: "75vh",
  maxHeight: "75vh",
  overflowY: "auto",
  padding: "0.5rem 1.5rem",
  backgroundColor: "rgba(49, 51, 50, 0.7)",
  boxShadow: `0px 0px 0px 3px ${mainGreen}`,
  borderRadius: "0.5rem",

  // Hide scrollbars
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
});

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "100%",
    border: 0,
    outline: 0,
    fontFamily: '"Poppins", sans-serif',
    fontSize: "1.5rem",
    color: "#fff",
  },
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: mainGreen,
  },
  "&:hover .MuiInput-underline:before": {
    borderBottomColor: `${mainGreenMiddle} !important`,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: mainGreenMax,
  },
});

const StyledMessage = styled(Typography)({
  color: mainSecondaryText,
  fontStyle: "italic",
});

const StyledSaveButton = styled(Button)({
  cursor: "pointer",
  width: "10rem",
  padding: "0.4rem 0",
  borderRadius: "0.5rem",
  borderWidth: "0px",
  marginTop: "2rem",
  backgroundColor: mainGreen,
  textAlign: "center",
  fontSize: "0.83rem",
  transition: "background-color 0.25s",
  color: "#fff",
});

interface PlaylistProps {
  playlistName: string;
  playlistTracks: SpotifyTrack[];
  removeTrack: (track: SpotifyTrack) => void;
  onNameChange: (name: string) => void;
  onSave: () => void;
}

const Playlist = ({
  playlistTracks,
  removeTrack,
  onNameChange,
  onSave,
}: PlaylistProps) => {
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onNameChange(event.target.value);
  };

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select();
    },
    []
  );

  // Then, wrap the TrackList component in the TrackListContainer in your JSX
  return (
    <StyledStack spacing={1}>
      <CustomTextField
        defaultValue="Playlist Name"
        placeholder="Enter a custom playlist name"
        onChange={handleNameChange}
        onFocus={handleFocus}
        variant="standard"
        fullWidth
      />
      {playlistTracks.length === 0 && (
        <StyledMessage align="center">
          Add tracks here after searching
        </StyledMessage>
      )}

      <TrackList
        tracks={playlistTracks}
        removeTrack={removeTrack}
        canRemoveTrack={true}
      />

      {playlistTracks.length > 0 && (
        <StyledSaveButton onClick={onSave}>SAVE TO SPOTIFY</StyledSaveButton>
      )}
    </StyledStack>
  );
};

export default Playlist;
