import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import TrackList from "components/TrackList";
import { mainGreen, mainSecondaryText } from "utils/colors";
import { SpotifyTrack } from "utils/constants";

const StyledStack = styled(Stack)({
  minHeight: "75vh",
  maxHeight: "75vh",
  overflowY: "auto",
  padding: "0.5rem 1.5rem",
  backgroundColor: "rgba(49, 51, 50, 0.7)",
  boxShadow: `0px 0px 0px 3px ${mainGreen}`,
  borderRadius: "0.5rem",

  // Hide scrollbars
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
});

const StyledLoadingSpinner = styled(CircularProgress)({
  color: mainGreen,
});

const StyledNoResultsText = styled(Typography)({
  fontSize: "1rem",
  fontStyle: "italic",
  color: mainSecondaryText,
});

interface Props {
  isLoading: boolean;
  searchResults: SpotifyTrack[];
  addTrack: (track: SpotifyTrack) => void;
  searchedTerm: string;
}

const SearchResults = ({
  isLoading,
  searchResults,
  addTrack,
  searchedTerm,
}: Props) => {
  return (
    <StyledStack spacing={1}>
      <Typography
        sx={{ fontFamily: '"Poppins", sans-serif', fontSize: "1.5rem" }}
      >
        Results
      </Typography>
      {isLoading ? (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <StyledLoadingSpinner />
          </Grid>
        </Grid>
      ) : searchResults.length > 0 ? (
        <TrackList
          tracks={searchResults}
          addTrack={addTrack}
          canRemoveTrack={false}
        />
      ) : (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            {searchedTerm ? (
              <StyledNoResultsText>No Tracks Found</StyledNoResultsText>
            ) : (
              <StyledNoResultsText>Search for tracks...</StyledNoResultsText>
            )}
          </Grid>
        </Grid>
      )}
    </StyledStack>
  );
};

export default SearchResults;
