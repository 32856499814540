import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { Grid, IconButton, Typography, styled } from "@mui/material";
import { albumInfoText, mainGreen } from "utils/colors";
import { SpotifyTrack } from "utils/constants";

const StyledAddIcon = styled(AddCircleOutlineRoundedIcon)({
  color: mainGreen,
});

const StyledRemoveIcon = styled(RemoveCircleOutlineRoundedIcon)({
  color: "#fa5757",
});

const StyledTrackInfoGrid = styled(Grid)({
  flexGrow: 1,
  flexDirection: "column",
  justifyContent: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const StyledSongTitle = styled(Typography)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "1rem",
});

const StyledBandWithAlbumDetails = styled(Typography)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "0.8rem",
  color: albumInfoText,
});

interface Props {
  track: SpotifyTrack;
  canRemoveTrack: boolean;
  addTrack?: (track: SpotifyTrack) => void;
  removeTrack?: (track: SpotifyTrack) => void;
}

const Track = ({ track, canRemoveTrack, addTrack, removeTrack }: Props) => {
  // const id = track.id;
  const trackName = track.name;
  const artistName = track.artists[0].name ?? "";
  // const uri = track.uri;
  const albumName = track.album.name;
  // const previewUrl = track.preview_url;
  const albumArt = track.album.images[2].url;

  const AddOrRemoveButton = (
    <IconButton
      onClick={() => {
        if (addTrack) {
          addTrack(track);
        }

        if (canRemoveTrack && removeTrack) {
          removeTrack(track);
        }
      }}
    >
      {canRemoveTrack ? <StyledRemoveIcon /> : <StyledAddIcon />}
    </IconButton>
  );

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{
        borderBottom: `0.5px solid ${mainGreen}`,
        padding: "0.5rem",
      }}
      wrap="nowrap"
    >
      <Grid item>
        <img
          src={`${albumArt}`}
          alt="Album art"
          style={{ borderRadius: "0.5rem" }}
        />
      </Grid>

      {/* {previewUrl && (
        <Grid item>
          <audio controls>
            <source src={previewUrl} type="audio/mpeg" />
          </audio>
        </Grid>
      )} */}

      <StyledTrackInfoGrid item>
        <StyledSongTitle title={trackName}>{trackName}</StyledSongTitle>

        <StyledBandWithAlbumDetails>
          {artistName} | <em>{albumName}</em>
        </StyledBandWithAlbumDetails>
      </StyledTrackInfoGrid>

      <Grid item>{AddOrRemoveButton}</Grid>
    </Grid>
  );
};

export default Track;
