import { useQuery } from "react-query";
import { APIQueryKey } from "../react-query/helpers";
import { SpotifyTrack } from "./constants";

const clientID = "f346a501a7804bef9ee653f9d5bd994b";
// const redirectUri = "http://kevins-playlist-website.surge.sh";
// const redirectUri = "https://www.jammming.net";
// const redirectUri = "https://jammming.net";
// const redirectUri = "http://localhost:3001";
let accessToken: string | undefined | null;

// interface TrackSearchRequest {
//   term?: string;
// }

const Spotify = {
  async getAccessToken() {
    if (accessToken) {
      window.localStorage.setItem("accessToken", accessToken);
      return accessToken;
    }

    const accessTokenMatch = window.location.href.match(/access_token=([^&]*)/);
    const expiresInMatch = window.location.href.match(/expires_in=([^&]*)/);

    if (accessTokenMatch && expiresInMatch) {
      accessToken = accessTokenMatch[1];
      const expiresIn = Number(expiresInMatch[1]);
      window.setTimeout(() => (accessToken = ""), expiresIn * 1000);
      window.history.pushState("Access Token", "", "/");
      return accessToken;
    } else {
      const redirectUri = window.location.href;
      const accessUrl = `https://accounts.spotify.com/authorize?client_id=${clientID}&response_type=token&scope=playlist-modify-public&redirect_uri=${redirectUri}`;
      window.location.href = accessUrl;
    }
  },

  async search(term: string) {
    try {
      const accessToken = await Spotify.getAccessToken();
      const response = await fetch(
        `https://api.spotify.com/v1/search?type=track&q=${term}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const jsonResponse = await response.json();
      if (!jsonResponse.tracks) {
        return [];
      }

      return jsonResponse.tracks.items.map((track: SpotifyTrack) => track);
    } catch (error) {
      console.error("Error occurred during search:", error);
      throw error;
    }
  },

  async savePlaylist(name: string, trackUris: string[]) {
    try {
      const accessToken = await Spotify.getAccessToken();
      const headers = { Authorization: `Bearer ${accessToken}` };

      const response = await fetch("https://api.spotify.com/v1/me", {
        headers: headers,
      });

      const jsonResponse = await response.json();
      const userId = jsonResponse.id;

      const playlistResponse = await fetch(
        `https://api.spotify.com/v1/users/${userId}/playlists`,
        {
          headers: headers,
          method: "POST",
          body: JSON.stringify({ name: name }),
        }
      );

      const playlistJsonResponse = await playlistResponse.json();
      const playlistId = playlistJsonResponse.id;
      return fetch(
        `https://api.spotify.com/v1/users/${userId}/playlists/${playlistId}/tracks`,
        {
          headers: headers,
          method: "POST",
          body: JSON.stringify({ uris: trackUris }),
        }
      );
    } catch (error) {
      console.error("Error occurred while saving playlist:", error);
      throw error;
    }
  },
};

export const useGetSpotifyTrackSearch = (term: string) => {
  return useQuery([APIQueryKey.retrieveTrackListBySearchTerm, term], () =>
    Spotify.search(term)
  );
};

// TODO: Implement save playlist + loading state
export const useSaveSpotifyPlaylist = (name: string, trackUris: string[]) => {
  return useQuery([APIQueryKey.saveSpotifyPlaylist, name], () =>
    Spotify.savePlaylist(name, trackUris)
  );
};

export default Spotify;
