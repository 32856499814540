import Playlist from "components/Playlist";
import SearchBar from "components/SearchBar";
import SearchResults from "components/SearchResults";
import { useEffect, useState } from "react";
import { SpotifyTrack } from "utils/constants";
import Spotify, { useGetSpotifyTrackSearch } from "utils/Spotify";

import { Box, Grid, Snackbar, styled, Typography } from "@mui/material";
import { SnackbarCloseReason } from "@mui/material/Snackbar";
import { mainGreenMax, mainWhite } from "utils/colors";

const StyledWrapper = styled(Box)({
  padding: "0 5% 10% 5%",
  backgroundColor: "#121212",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  fontFamily: '"Work Sans", sans-serif',
  fontWeight: 500,
  color: mainWhite,
});

const StyledAppBannerText = styled(Typography)({
  padding: "1rem",
  textAlign: "center",
  fontFamily: '"Poppins", sans-serif',
  fontSize: "2rem",
  color: mainWhite,
});

const StyledInvertedText = styled("span")({
  color: "transparent",
  WebkitTextStroke: `0.8px ${mainGreenMax}`,
});

const Jammming = () => {
  const [showSnackbar, setShowSnackbar] = useState(true);
  const [playlistName, setPlaylistName] = useState("My Playlist");
  const [playlistTracks, setPlaylistTracks] = useState<SpotifyTrack[]>([]);
  const [searchResults, setSearchResults] = useState<SpotifyTrack[]>([]);

  const addTrack = (track: SpotifyTrack) => {
    const isTrackAlreadyAdded = playlistTracks.find(
      (savedTrack: SpotifyTrack) => savedTrack.id === track.id
    );

    if (isTrackAlreadyAdded) {
      return;
    }
    const updatedTracks = playlistTracks.concat(track);
    setPlaylistTracks(updatedTracks);
  };

  const removeTrack = (track: SpotifyTrack) => {
    let tracks = playlistTracks;
    tracks = tracks.filter(
      (currentTrack: SpotifyTrack) => currentTrack.id !== track.id
    );

    setPlaylistTracks(tracks);
  };

  const updatePlaylistName = (name: string) => {
    setPlaylistName(name);
  };

  const savePlaylist = () => {
    const trackUris = playlistTracks.map((track: SpotifyTrack) => track.uri);

    Spotify.savePlaylist(playlistName, trackUris).then(() => {
      setPlaylistName("Playlist Name");
      setPlaylistTracks([]);
    });
  };

  const [term, setTerm] = useState("");
  const [searchedTerm, setSearchedTerm] = useState("");

  const getSpotifyTrackList = useGetSpotifyTrackSearch(searchedTerm);
  const isLoading = getSpotifyTrackList.isLoading;
  const data = getSpotifyTrackList.data;

  useEffect(() => {
    if (data) {
      setSearchResults(data);
    }
  }, [data]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="This app gets updated every few days so please check back every now and then!"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />

      <StyledWrapper>
        <StyledAppBannerText variant="h3">
          Ja<StyledInvertedText>mmm</StyledInvertedText>ing
        </StyledAppBannerText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchBar
              isLoading={isLoading}
              term={term}
              setTerm={setTerm}
              setSearchedTerm={setSearchedTerm}
              setSearchResults={setSearchResults}
            />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <SearchResults
                isLoading={isLoading}
                searchResults={searchResults}
                addTrack={addTrack}
                searchedTerm={searchedTerm}
              />
            </Grid>
            <Grid item xs={6}>
              <Playlist
                playlistName={playlistName}
                playlistTracks={playlistTracks}
                removeTrack={removeTrack}
                onNameChange={updatePlaylistName}
                onSave={savePlaylist}
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledWrapper>
    </>
  );
};

export default Jammming;
