import { QueryClient } from "react-query";

export enum APIQueryKey {
  // Search-related keys
  retrieveTrackListBySearchTerm = "retrieveTrackListBySearchTerm",
  saveSpotifyPlaylist = "saveSpotifyPlaylist",
}

export const queryClientConfig = new QueryClient({
  defaultOptions: {
    // Queries defaults
    // https://react-query.tanstack.com/reference/useQuery
    // queries: {
    //   // Don't retry failed queries by default
    //   retry: false,
    //   refetchOnWindowFocus: false,
    // },
  },
});
