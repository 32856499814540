import { StyledEngineProvider } from "@mui/material";
import Jammming from "components/Jammming";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <Jammming />
    </StyledEngineProvider>
  );
};

export default App;
