import React from "react";
import { QueryClientProvider } from "react-query";
import { queryClientConfig } from "./helpers";
// import { ReactQueryDevtools } from 'react-query/devtools';

interface Props {
  children: React.ReactNode;
}

const CustomQueryClientProvider = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClientConfig}>
      {/* Optionally enable react-query devtools here if needed */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {children}
    </QueryClientProvider>
  );
};

export default CustomQueryClientProvider;
